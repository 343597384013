<template>
  <div
    :class="{
      hidden: !isTableShow,
    }"
  >
    <DevExpressLocalGrid
      ref="grid"
      :gridId="gridId"
      :isLookupRelationTable="true"
      :element="element"
      :table="table"
    />
  </div>
  <div
    v-if="!isTableShow"
    class="w-100 bg-info h-40px d-flex align-items-center justify-content-between text-capitalize ps-2 fs-5 text-white cursor-pointer"
    @click="tableShowing"
    :style="{
      background: String.isNullOrWhiteSpace(element.linearGradient)
        ? ''
        : element.linearGradient + '!important',
      color: String.isNullOrWhiteSpace(element.fontColor)
        ? ''
        : element.fontColor + '!important',
    }"
  >
    {{ table.name }}
  </div>
</template>
<script>
import DevExpressLocalGrid from "@/components/devexpress/grid/local/Grid.vue";
export default {
  name: "LocalTable",
  props: [
    "element",
    "table",
    "tableSortOptions",
    "buttons",
    "tableValues",
    "crudType",
  ],
  components: {
    DevExpressLocalGrid,
  },
  data() {
    return {
      gridId: String.format("Table_{0}", this.element.id),
      isTableShow: this.table && this.table.isLoadedOnFirstRequest,
    };
  },
  methods: {
    tableShowing() {
      this.isTableShow = true;

      setTimeout(() => {
        this.$refs.grid.configurationSxScroll();
      }, 100);
    },
  },
  mounted() {
    //
  },
  created() {
    //
  },
};
</script>
