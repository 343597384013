<template>
  <div class="card" :id="`tab_${tabId}`" :class="[element.customClasses]">
    <div class="card-header">
      <div
        class="nav card-header-tabs w-100"
        :class="[
          element.tabType,
          { 'nav-justified': element.isJustified },
          { 'pb-2': element.tabType === 'nav-pills' },
          { 'border-0': element.items.length === 0 },
        ]"
        :id="element.id"
        role="tablist"
      >
        <template v-if="isNextStepRequiredFieldValidation && !isDetailPage">
          <button
            v-for="(item, index) in element.items"
            :key="item.id"
            class="nav-link mt-2"
            :class="
              String.format(
                '{0} {1} {2}',
                item.labelClassNames !== undefined ? item.labelClassNames : '',
                index === 0 ? 'active' : '',
                index == element.items.length - 1 ? '' : 'me-1'
              )
            "
            :style="{
              background: String.isNullOrWhiteSpace(item.linearGradient)
                ? ''
                : item.linearGradient + '!important',
              color: String.isNullOrWhiteSpace(item.fontColor)
                ? ''
                : item.fontColor + '!important',
            }"
            :id="`nav-${index}-tab`"
            :data-sx-target="`#nav-${item.id}`"
            :data-index="`${index}`"
            :aria-controls="item.id"
            @click="tabNavLinkClick($event, index)"
            type="button"
            role="tab"
            :aria-selected="index === 0"
          >
            {{ item.name }}
          </button>
        </template>
        <template v-else>
          <button
            v-for="(item, index) in element.items"
            :key="item.id"
            class="nav-link mt-2"
            :class="
              String.format(
                '{0} {1} {2}',
                item.labelClassNames !== undefined ? item.labelClassNames : '',
                index === 0 ? 'active' : '',
                index == element.items.length - 1 ? '' : 'me-1'
              )
            "
            :style="{
              background: String.isNullOrWhiteSpace(item.linearGradient)
                ? ''
                : item.linearGradient + '!important',
              color: String.isNullOrWhiteSpace(item.fontColor)
                ? ''
                : item.fontColor + '!important',
            }"
            :id="`nav-${index}-tab`"
            data-bs-toggle="tab"
            :data-bs-target="`#nav-${item.id}`"
            :data-sx-target="`#nav-${item.id}`"
            :aria-controls="item.id"
            type="button"
            role="tab"
            @click="lookupRelationTableLoad"
            :aria-selected="index === 0"
          >
            {{ item.name }}
          </button>
        </template>
      </div>
    </div>
    <div class="card-body">
      <div class="tab-content" :id="`nav-tabContent-${element.id}`">
        <div
          v-for="(item, index) in element.items"
          :key="item.id"
          class="tab-pane fade"
          :class="{
            'show active': index === 0,
            'not-loaded': index > 0,
          }"
          :id="`nav-${item.id}`"
          :data-index="`${index}`"
          role="tabpanel"
          :aria-labelledby="`nav-${item.id}-tab`"
        >
          <div v-html="item.content"></div>
          <DesignLayoutChild
            :customObjectItems="customObjectItems"
            :items="item.items"
          />
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-center bg-light"
      v-if="isNextStepRequiredFieldValidation && !isDetailPage"
    >
      <button
        type="button"
        class="btn btn-danger mb-2 me-2 mt-2 btn-sm"
        @click="tabNextPrev('back')"
        v-if="activeIndex !== 0"
      >
        <i class="far fa-hand-point-left"></i>
        {{
          $t(
            "BaseModelFields.Back",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>
      <button
        type="button"
        class="btn btn-success mb-2 mt-2 btn-sm"
        @click="tabNextPrev('next')"
        v-if="element.items.length - 1 !== activeIndex"
      >
        <i class="far fa-hand-point-right"></i>
        {{
          $t(
            "BaseModelFields.Next",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import DesignLayoutChild from "../DesignLayoutChild.vue";
export default {
  props: ["element", "customObjectItems"],
  name: "TabItem",
  data() {
    return {
      activeIndex: 0,
      isValid: !this.isNextStepRequiredFieldValidation,
      tabValidatedClassAdding: false,
      isNextStepRequiredFieldValidation:
        this.element.isNextStepRequiredFieldValidation,
      isDetailPage: this.$route.name === "detail",
      tabId: String.newGuid(),
    };
  },
  components: {
    DesignLayoutChild,
  },
  methods: {
    tabValidation() {
      if (!this.isNextStepRequiredFieldValidation) {
        this.isValid = true;
        return true;
      }

      var isValid = this.isValid;
      isValid = true;
      var form = $(String.format("#nav-tabContent-{0}", this.element.id)).find(
        String.format("[data-index='{0}']", this.activeIndex)
      );

      $(String.format("#tab_{0}", this.tabId)).addClass("was-validated");
      $.each(
        form.find("input[required], textarea[required], select[required]"),
        function (i, v) {
          var item = $(v);
          if (
            item.is(":disabled") &&
            item.hasClass("select2-hidden-accessible")
          ) {
            isValid = !String.isNullOrWhiteSpace(
              $(String.format("#SELECT-TEXT-{0}", item.attr("id"))).val()
            );
          } else if (!v.checkValidity()) {
            isValid = false;
          }
        }
      );

      this.isValid = isValid;
      if (!this.isValid) this.tabValidatedClassAdding = true;

      return isValid;
    },
    tabNavLinkClick(e, index) {
      if (this.tabValidation() || index === this.activeIndex) {
        this.lookupRelationTableLoad(e);
        this.activeIndex = index;
        $(
          String.format("#nav-tabContent-{0} .tab-pane", this.element.id)
        ).removeClass("show active");
        $(String.format("#{0} .nav-link", this.element.id)).removeClass(
          "active"
        );
        $(
          String.format(
            "#{0} .nav-link[data-index={1}]",
            this.element.id,
            index
          )
        ).addClass("active");
        $(
          String.format(
            "#nav-tabContent-{0} .tab-pane[data-index={1}]",
            this.element.id,
            index
          )
        ).addClass("show active");
        $(String.format("#tab_{0}", this.tabId)).removeClass("was-validated");
      }
    },
    lookupRelationTableLoad(e) {
      var tabContentId = $(e.target).data("sx-target"),
        lookupRelationTables = $(tabContentId).find(".lookup-relation-table");
      if (lookupRelationTables.length > 0) {
        lookupRelationTables.each(function (i, lr) {
          lr = $(lr);
          var isLoadedOnFirstRequest = lr.data("isloadedonfirstrequest");
          if (isLoadedOnFirstRequest) {
            lr.trigger("click");
          } else {
            lr.addClass("sx-panel-draw-end");
          }
        });
      }
    },
    tabNextPrev(type) {
      if (type == "back" && !this.tabValidation()) {
        this.activeIndex = this.activeIndex - 1;
        $(
          String.format(
            "#{0} .nav-link[data-index={1}]",
            this.element.id,
            this.activeIndex
          )
        )
          .removeAttr("disabled")
          .trigger("click");
        return;
      }

      if (!this.tabValidation()) return;
      if (type == "back") {
        this.activeIndex = this.activeIndex - 1;
      } else {
        this.activeIndex = this.activeIndex + 1;
      }

      $(
        String.format(
          "#{0} .nav-link[data-index={1}]",
          this.element.id,
          this.activeIndex
        )
      )
        .removeAttr("disabled")
        .trigger("click");
    },
  },
};
</script>
