<template>
  <div
    class="accordion"
    :id="`accordion-${element.id}`"
    :class="[element.customClasses]"
  >
    <div
      class="accordion-item"
      v-for="(item, index) in element.items"
      :key="item.id"
    >
      <h2 class="accordion-header" :id="`header-${item.id}`">
        <button
          class="accordion-button"
          :class="{ collapsed: index !== 0 }"
          :style="{
            background: String.isNullOrWhiteSpace(item.linearGradient)
              ? ''
              : item.linearGradient + '!important',
            color: String.isNullOrWhiteSpace(item.fontColor)
              ? ''
              : item.fontColor + '!important',
          }"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="`#collapse-${item.id}`"
          :aria-expanded="index === 0"
          :aria-controls="`collapse-${item.id}`"
        >
          {{ item.name }}
        </button>
      </h2>
      <div
        :id="`collapse-${item.id}`"
        class="accordion-collapse collapse"
        :class="{ show: index === 0 }"
        :aria-labelledby="`header-${item.id}`"
        :data-bs-parent="`#accordion-${element.id}`"
      >
        <div class="accordion-body">
          <div v-if="item.content !== ''" v-html="item.content" class="mb-3" />
          <DesignLayoutChild
            :customObjectItems="customObjectItems"
            :items="item.items"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DesignLayoutChild from "../DesignLayoutChild.vue";

export default {
  props: ["element", "customObjectItems"],
  name: "AccordionItem",
  components: {
    DesignLayoutChild,
  },
};
</script>
