<template>
  <input
    type="hidden"
    class="hidden system-hidden-field"
    id="CustomObjectPublicId"
    name="CustomObjectPublicId"
    :value="pageData.customObjectPublicId"
  />
  <input
    type="hidden"
    class="hidden system-hidden-field"
    id="CustomObjectKey"
    name="CustomObjectKey"
    :value="pageData.customObjectKey"
  />
  <input
    type="hidden"
    class="hidden system-hidden-field"
    id="IsButtonSaveAndNewVal"
    name="IsButtonSaveAndNewVal"
    :value="pageData.isButtonSaveAndNew"
  />
  <input
    type="hidden"
    class="hidden system-hidden-field"
    id="LayoutFormulaName"
    name="LayoutFormulaName"
    :value="pageData.layout ? pageData.layout.formulaName : ''"
  />
  <input
    type="hidden"
    class="hidden system-hidden-field"
    id="LayoutPublicId"
    name="LayoutPublicId"
    :value="pageData.layout ? pageData.layout.publicId : ''"
  />
  <input
    type="hidden"
    id="CurrentLocation"
    name="X-Current-Location"
    class="hidden system-hidden-field"
    :value="Object.readCookie('CurrentLocation')"
  />
  <input
    type="hidden"
    id="RecordPublicId"
    class="hidden system-hidden-field"
    :value="pageData.recordPublicId"
  />
  <input
    type="hidden"
    id="Version"
    class="hidden system-hidden-field"
    name="Version"
    :value="pageData.version"
    v-if="crudType !== 'New'"
  />
</template>
<script>
export default {
  name: "HiddenFields",
  props: ["pageData", "crudType"],
};
</script>
