<template>
  <div
    class="border p-3"
    :class="[
      `border-${element.color}`,
      element.borderClass,
      element.customClasses,
    ]"
    :style="{
      'border-image': String.isNullOrWhiteSpace(element.linearGradient)
        ? ''
        : element.linearGradient + '!important',
    }"
    style="border-image-slice: 1 !important"
  >
    <DesignLayoutChild
      :customObjectItems="customObjectItems"
      :items="element.items"
    />
  </div>
</template>

<script>
import DesignLayoutChild from "../DesignLayoutChild.vue";

export default {
  props: ["element", "customObjectItems"],
  name: "BorderItem",
  components: {
    DesignLayoutChild,
  },
};
</script>
