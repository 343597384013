<template>
  <div
    class="position-relative view-edit-mode"
    :class="{ hidden: isDetailPage }"
  >
    <input
      ref="readOnlyInput"
      class="form-control"
      v-model="readOnlyValue"
      :spellcheck="$isTextSpellCheck"
      :id="element.id"
      :name="element.id"
      :disabled="true"
      :required="false"
      :autocomplete="$isAutoComplete"
      :data-oldvalue="oldValue"
      :class="
        element.valueClassNames !== undefined ? element.valueClassNames : ''
      "
    />
  </div>
  <div class="view-detail-mode" v-if="isDetailPage">
    <span :id="field.publicId" class="d-flex text-break">{{
      readOnlyValue
    }}</span>
  </div>
</template>
<script>
export default {
  name: "ReadOnly",
  props: ["element", "field", "value", "crudType"],
  data() {
    return {
      isDetailPage: this.crudType === "DETAIL",
      isNewPage: this.crudType === "NEW",
      readOnlyValue: this.value,
      oldValue: "",
    };
  },
  methods: {},
  mounted() {
    if (!String.isNullOrWhiteSpace(this.value)) {
      if (this.field.fieldType == "RollupDate") {
        this.readOnlyValue = this.$root.dateFormat(this.value);
      } else if (this.field.fieldType == "RollupDateTime") {
        this.readOnlyValue = this.$root.dateTimeFormat(this.value);
      } else {
        this.readOnlyValue = this.value;
      }
      this.oldValue = this.readOnlyValue;
    }
  },
};
</script>
