<template>
  <DesignItem
    v-for="(element, index) in items"
    :key="element.id"
    :class="getClass(element)"
    :element="element"
    :index="index"
    :customObjectItems="customObjectItems"
    :items="items"
    :data="data"
  />
</template>

<script>
import DesignItem from "./DesignItem.vue";

export default {
  props: ["customObjectItems", "items", "data"],
  name: "DesignLayoutChild",
  watch: {
    "$store.state.setPageDto"() {
      this.setPageDto = this.$store.state.setPageDto;
    },
  },
  data() {
    return {
      setPageDto: this.$store.state.setPageDto,
    };
  },
  components: {
    DesignItem,
  },
  beforeCreate() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    this.$options.components.DesignItem = require("./DesignItem.vue").default;
  },
  methods: {
    getClass(element) {
      var isMobileLayout = false,
        customClasses = !String.isNullOrWhiteSpace(element.customClasses)
          ? element.customClasses
          : "";
      if (this.setPageDto && this.setPageDto.layout) {
        isMobileLayout = this.setPageDto.layout.isMobileLayout;
      }
      if (this.$isMobile() && !isMobileLayout) {
        if (element.type === "row") {
          return "row row-cols-1";
        }

        return element.type === "column" ? `col p-2 ${customClasses}` : "";
      } else {
        if (element.type === "row") {
          return "row";
        }
        return element.type === "column"
          ? `${element.className} p-2 ${customClasses}`
          : "";
      }
    },
  },
};
</script>

<style scoped>
/* .col:empty {
  background-color: rgba(166, 166, 166, 0.05);
  min-height: 4rem;
} */
</style>
